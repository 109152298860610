import pageDetailsHandler from "@/services/PageDetails";

export default {
  async load({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await pageDetailsHandler.all(payload);
      commit("SET_PAGE_DETAILS", data);
    } catch (error) {
      commit("SET_PAGE_DETAILS", []);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async store({ commit, dispatch }, payload) {
    return pageDetailsHandler.store(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async get({ commit, dispatch }, payload) {
    return pageDetailsHandler.get(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async update({ commit, dispatch }, payload) {
    return pageDetailsHandler.update(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async remove({ commit, dispatch }, payload) {
    return pageDetailsHandler.remove(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async destroy({ commit, dispatch }, payload) {
    return pageDetailsHandler.destroy(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async restore({ commit, dispatch }, payload) {
    return pageDetailsHandler.restore(payload);
  },
};
