<template>
  <v-card class="mx-auto">
    <v-navigation-drawer
      v-model="getDrawer"
      color="indigo-darken-3"
      class="mx-auto"
      width="300"
    >
      <v-sheet color="indigo-darken-3" class="px-5 py-3 pt-5">
        <v-img
          :src="require('@/assets/logo.png')"
          width="160"
          aspect-ratio="16/7"
        ></v-img>
      </v-sheet>
      <br />
      <v-divider></v-divider>
      <br />
      <v-list v-model:opened="open">
        <template
          v-for="{ icon, title, path, submenu, permission } in menu"
          :key="title"
        >
          <v-list-group
            :value="title"
            v-if="submenu.length > 0"
            v-show="hasPermission(permission)"
          >
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" :prepend-icon="icon" :title="title" />
            </template>
            <v-list-item
              v-for="(
                { submenuIcon, submenuTitle, submenuPath, permission }, i
              ) in submenu"
              v-show="hasPermission(permission)"
              :key="i"
              :prepend-icon="submenuIcon"
              :title="submenuTitle"
              :value="submenuTitle"
              :to="submenuPath"
            />
          </v-list-group>
          <v-list-item
            v-show="hasPermission(permission)"
            v-else
            :prepend-icon="icon"
            :title="title"
            :to="path"
            link
          />
        </template>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <add-button
            :block="true"
            text="Logout"
            :loading="loading"
            @onButtonClicked="logoutHandler"
          />
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import Auth from "@/mixins/Auth";
import AddButton from "@/components/butttons/AddButton";
import RolePermissions from "@/mixins/RolePermissions";

export default {
  name: "SidebarMenu",
  components: { AddButton },
  mixins: [Auth, RolePermissions],
  data() {
    return {
      loading: false,
      open: ["Dashboard"],
      menu: [
        {
          icon: "mdi-home",
          title: "Dashboard",
          path: "/",
          permission: "dashboard",
          submenu: [],
        },
        {
          icon: "mdi-book-open-page-variant",
          title: "Pages",
          permission: "pages-menu",
          submenu: [
            {
              submenuIcon: "mdi-home",
              submenuTitle: "Home",
              submenuPath: "/page/home",
              permission: "home-page",
            },
            {
              submenuIcon: "mdi-book-open",
              submenuTitle: "About",
              submenuPath: "/page/about",
              permission: "about-page",
            },
            {
              submenuIcon: "mdi-contacts",
              submenuTitle: "Contact Us",
              submenuPath: "/page/contact",
              permission: "contact-page",
            },
            {
              submenuIcon: "mdi-cash",
              submenuTitle: "Quote",
              submenuPath: "/page/quote",
              permission: "quote-page",
            },
            {
              submenuIcon: "mdi-book-open-variant",
              submenuTitle: "Case Study",
              submenuPath: "/page/case-study",
              permission: "case-study-page",
            },
            {
              submenuIcon: "mdi-comment-question-outline",
              submenuTitle: "Faq",
              submenuPath: "/page/faq",
              permission: "faq-page",
            },
            {
              submenuIcon: "mdi-briefcase",
              submenuTitle: "Products",
              submenuPath: "/page/products",
              permission: "products-page",
            },
            {
              submenuIcon: "mdi-folder-lock",
              submenuTitle: "Privacy Policy",
              submenuPath: "/page/privacy-policy",
              permission: "privacy-policy-page",
            },
            {
              submenuIcon: "mdi-folder-lock-open",
              submenuTitle: "Terms of Service",
              submenuPath: "/page/terms-of-service",
              permission: "terms-of-service-page",
            },
          ],
        },
        {
          icon: "mdi-book-multiple",
          title: "Page Details",
          path: "/page/details",
          permission: "page-details",
          submenu: [],
        },
        {
          icon: "mdi-message-draw",
          title: "Projects",
          path: "/projects",
          permission: "projects",
          submenu: [],
        },
        {
          icon: "mdi-football-helmet",
          title: "Services",
          path: "/services",
          permission: "services",
          submenu: [],
        },
        {
          icon: "mdi-account-multiple",
          title: "Teams",
          path: "/teams",
          permission: "teams",
          submenu: [],
        },
        {
          icon: "mdi-account-network",
          title: "Testimonials",
          path: "/testimonials",
          permission: "testimonials",
          submenu: [],
        },
        {
          icon: "mdi-comment-question-outline",
          title: "Faqs",
          path: "/faqs",
          permission: "faqs",
          submenu: [],
        },
        {
          icon: "mdi-glasses",
          title: "Technologies",
          path: "/technologies",
          permission: "technologies",
          submenu: [],
        },
        {
          icon: "mdi-cog",
          title: "Role & Permission",
          permission: "role-permission-menu",
          submenu: [
            {
              submenuIcon: "mdi-account-multiple",
              submenuTitle: "Users",
              submenuPath: "/auth/users",
              permission: "user-list",
            },
            {
              submenuIcon: "mdi-format-list-bulleted-type",
              submenuTitle: "Role",
              submenuPath: "/auth/users/roles",
              permission: "role-list",
            },
            {
              submenuIcon: "mdi-folder-lock",
              submenuTitle: "Permissions",
              submenuPath: "/permissions",
              permission: "permission-list",
            },
          ],
        },
      ],
      isLogoutLoading: false,
    };
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getDrawer() {
      return this.drawer;
    },
  },
};
</script>
