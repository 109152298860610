import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
const dataState = createPersistedState({
  paths: ["auth.authenticated", "auth.verified", "auth.user", "auth.token"],
});

import role from "./role";
import permission from "./permission";
import team from "./team";
import testimonial from "./testimonial";
import faq from "./faq";
import technology from "./technology";
import pageDetail from "./page-detail";
import project from "./project";
import service from "./service";
export default createStore({
  modules: {
    auth,
    role,
    permission,
    team,
    testimonial,
    faq,
    technology,
    pageDetail,
    project,
    service,
  },
  plugins: [dataState],
  state: {
    snackBar: {},
    overlay: false,
  },
  getters: {},
  mutations: {
    SET_SNACKBAR: function (state, payload) {
      state.snackBar = payload;
    },
    SET_OVERLAY: function (state, payload) {
      state.overlay = payload;
    },
  },
  actions: {},
});
