import testimonialHandler from "@/services/Testimonial";

export default {
  async load({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await testimonialHandler.all(payload);
      commit("SET_TESTIMONIAL", data);
    } catch (error) {
      commit("SET_TESTIMONIAL", []);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async store({ commit, dispatch }, payload) {
    return testimonialHandler.store(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async get({ commit, dispatch }, payload) {
    return testimonialHandler.get(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async update({ commit, dispatch }, payload) {
    return testimonialHandler.update(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async remove({ commit, dispatch }, payload) {
    return testimonialHandler.remove(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async destroy({ commit, dispatch }, payload) {
    return testimonialHandler.destroy(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async restore({ commit, dispatch }, payload) {
    return testimonialHandler.restore(payload);
  },
};
