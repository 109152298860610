import Api from "@/services/Api";
const basePath = "/page/detail";
const all = (payload) => {
  const path = `${basePath}/all`;
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

const get = (payload) => {
  const endpoint = `${basePath}/${payload.id}`;
  return Api.getRequest({
    endpoint,
  });
};

const store = (payload) => {
  return Api.postRequest({
    endpoint: `${basePath}/store`,
    data: payload,
  });
};

const update = (payload) => {
  return Api.postRequest({
    endpoint: `${basePath}/update/${payload.id}`,
    data: payload,
  });
};

const remove = (payload) => {
  return Api.deleteRequest({
    endpoint: `${basePath}/remove/${payload.id}`,
  });
};

const destroy = (payload) => {
  return Api.deleteRequest({
    endpoint: `${basePath}/destroy/${payload.id}`,
  });
};

const restore = (payload) => {
  return Api.postRequest({
    endpoint: `${basePath}/restore/${payload.id}`,
    method: "PUT",
    data: {},
  });
};

export default {
  all,
  get,
  store,
  update,
  remove,
  destroy,
  restore,
};
