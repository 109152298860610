import AppSidebar from "@/layouts/AppSidebar";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView"),
    meta: { guestOnly: true },
  },
  {
    path: "/",
    name: "Dashboard",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/DashboardView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/profile-settings",
    name: "ProfileSettings",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/ProfileSettings"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/auth/users",
    name: "AuthUsers",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/UsersView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/auth/users/roles",
    name: "AuthUsersRoles",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/UserRolesView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/permissions",
    name: "Permissions",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/PermissionView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/teams",
    name: "Teams",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/TeamsView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/TestimonialView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/faqs",
    name: "Faqs",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/FaqsView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/technologies",
    name: "Technologies",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/TechnologyView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/page/home",
    name: "PageHome",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/HomeView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/page/about",
    name: "PageAbout",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/AboutView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/page/contact",
    name: "PageContact",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/ContactView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/page/quote",
    name: "PageQuote",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/QuoteView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/page/case-study",
    name: "PageCaseStudy",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/CaseStudyView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/page/faq",
    name: "PageFaq",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/FaqView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/page/products",
    name: "PageProducts",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/ProductsView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/page/privacy-policy",
    name: "PagePrivacyPolicy",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/PrivacyPolicyView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/page/terms-of-service",
    name: "PageTermsOfService",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/TermsOfServiceView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/page/details",
    name: "PageDetails",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/PageDetailsView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/projects",
    name: "Projects",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/ProjectsView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/services",
    name: "Services",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/ServicesView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/add-service",
    name: "AddService",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/AddServiceView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/service/edit/:id",
    name: "EditService",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/EditServiceView"),
    },
    meta: { authOnly: true },
  },
];

export default routes;
