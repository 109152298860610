import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { vuetifyProTipTap } from "./plugins/tiptap";
import { loadFonts } from "./plugins/webfontloader";
import VueObserveVisibility from "vue3-observe-visibility";
import { VueHeadMixin, createHead } from "@unhead/vue";
const head = createHead();
loadFonts().then();
const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(vuetifyProTipTap)
  .use(VueObserveVisibility)
  .use(head)
  .mixin(VueHeadMixin);

//await router.isReady();
router.isReady().then(() => app.mount("#app"));
