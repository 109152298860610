<template>
  <v-app id="inspire">
    <snackbar-component />
    <router-view name="appBar" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import SnackbarComponent from "@/components/Snackbar";
export default {
  metaInfo() {
    return {
      title: "My Options API title",
      description: "My Options API title",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },
  name: "App",
  components: { SnackbarComponent },
  data: () => ({}),
};
</script>
