import faqHandler from "@/services/Faq";

export default {
  async load({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await faqHandler.all(payload);
      commit("SET_FAQS", data);
    } catch (error) {
      commit("SET_FAQS", []);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async store({ commit, dispatch }, payload) {
    return faqHandler.store(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async get({ commit, dispatch }, payload) {
    return faqHandler.get(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async update({ commit, dispatch }, payload) {
    return faqHandler.update(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async remove({ commit, dispatch }, payload) {
    return faqHandler.remove(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async destroy({ commit, dispatch }, payload) {
    return faqHandler.destroy(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async restore({ commit, dispatch }, payload) {
    return faqHandler.restore(payload);
  },
};
