import serviceHandler from "@/services/Service";

export default {
  async load({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await serviceHandler.all(payload);
      commit("SET_SERVICES", data);
    } catch (error) {
      commit("SET_SERVICES", []);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async store({ commit, dispatch }, payload) {
    return serviceHandler.store(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async get({ commit, dispatch }, payload) {
    return serviceHandler.get(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async update({ commit, dispatch }, payload) {
    return serviceHandler.update(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async remove({ commit, dispatch }, payload) {
    return serviceHandler.remove(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async destroy({ commit, dispatch }, payload) {
    return serviceHandler.destroy(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async restore({ commit, dispatch }, payload) {
    return serviceHandler.restore(payload);
  },
};
